/**
 * @name 景点管理
 */
import request from '@/utils/request'

export const getClientsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/clients/setup'
  })
}

export const getClientsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/clients',
    params
  })
}

export const getClientsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/clients/${id}/preview`
  })
}

export const getClientsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/clients/${id}`
  })
}

export const getClientsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/clients/collections'
  })
}

export const putClientsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/clients/${id}`,
    data
  })
}

export const getCountrySetup = () => {
  return request({
    method: 'GET',
    url: '/cms/countries/setup'
  })
}

export const getCountryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/countries',
    params
  })
}

export const deleteCountry = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/countries/${id}`
  })
}

export const postCountry = (data) => {
  return request({
    method: 'POST',
    url: 'cms/countries',
    data
  })
}

export const getCountryPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/countries/${id}/preview`
  })
}

export const putCountry = (id, data) => {
  return request({
    method: 'PUT',
    url: `cms/countries/${id}`,
    data
  })
}

export const getCountryDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/countries/${id}`
  })
}

/**
 * @name 管理员管理
**/

export const getAdminsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/admin_users/setup'
  })
}

export const getAdminsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/admin_users',
    params
  })
}

export const deleteAdmins = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/admin_users/${id}`
  })
}

export const getAdminsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/admin_users/${id}/preview`
  })
}

export const getAdminsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/admin_users/collections'
  })
}

export const putAdminsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/admin_users/${id}`,
    data
  })
}

export const postAdminsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/admin_users',
    data
  })
}

export const getAdminsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/admin_users/${id}`
  })
}

//酒店城市设置
export const getRestCityList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rest_cities',
    params
  })
}

export const deleteRestCity = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/rest_cities/${id}`
  })
}

export const getRestCityInfo = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_cities/${id}`
  })
}

export const postRestCity = (rest_city) => {
  return request({
    method: 'POST',
    url: '/cms/rest_cities',
    data: {
      rest_city
    }
  })
}

export const previewRestCity = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_cities/${id}/preview`
  })
}

export const updateCityInfo = (id, rest_city) => {
  return request({
    method: 'PUT',
    url: `/cms/rest_cities/${id}`,
    data: {
      rest_city
    }
  })
}