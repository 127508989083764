export default {
  basicInformation: [{
    prop: 'avatar',
    label: '',
    Object: 'value',
    image: true
  }, {
    prop: 'email',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'remark',
    label: '',
    Object: 'value'
  }, {
    prop: 'locale',
    label: '',
    Object: 'value'
  }, {
    prop: 'role',
    label: '',
    Object: 'value'
  }, {
    prop: 'last_sign_in_at',
    label: '',
    Object: 'value'
  }]
}
